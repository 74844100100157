import React, { useRef, useState } from "react";
import ReactDOM from 'react-dom';
// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/free-mode/free-mode.scss'; // Free mode module

import './styles.css';

// Core modules imports are same as usual
import { FreeMode, Pagination } from 'swiper';

import Stars from '../shared/stars'

class ImageSlider extends React.Component {

	constructor(props) {
		super(props);
	}

	renderInfo = (info) => {
		if( !info ) {
			return null;
		} else {
			switch( info.type ) {
				case 'location':
					return (
						<p className="product-grid__product--heading">
							<span className="icon icon-location">
								<span className="path1"></span>
							</span>
							<strong>{info.title}</strong>
							<span>
							<br />
							{info.products} listings
							</span>
						</p>
					)
				break;
				case 'product':
					return (
						<div className="product-grid__product--heading">
							<span><strong>{info.title}, {info.location}</strong></span>

							<div className="product-grid__product--price">From <span>{info.price}</span> per {info.fixedPrice ? 'group' : 'person'}</div>
							{info.reviews?
								<div className="product-grid__product--reviews">
									<Stars rating={info.reviews.rating} /> {info.reviews.total} Reviews
								</div>
							:null}
						</div>
					)
				break;
				case 'category':
					return (
						<div className="product-grid__product--heading">
							<span><strong>{info.title}</strong></span>
							<div className="product-grid__product--price">From <span>{info.price}</span> per {info.fixedPrice ? 'group' : 'person'}</div>
						</div>
					)
				break;

			}
		}
	}

	render() {

		const initialSlides = this.props.initialSlides != undefined && this.props.initialSlides != null ? this.props.initialSlides : 3;

		return (
			<Swiper
				slidesPerView={1}
				spaceBetween={10}
				freeMode={true}
				loop={true}
				pagination={{
					clickable: true,
				}}
				breakpoints={{
					"@0.00": {
						slidesPerView: 1,
						spaceBetween: 10,
					},
					"@0.50": {
						slidesPerView: 1,
						spaceBetween: 10,
					},
					"@0.75": {
						slidesPerView: 2,
						spaceBetween: 20,
					},
					"@1.00": {
						slidesPerView: parseInt( initialSlides ),
						spaceBetween: 20,
					},
					"@2.00": {
						slidesPerView: 4,
						spaceBetween: 20,
					},
				}}
				modules={[FreeMode, Pagination]}
				className="mySwiper"
			>

			{this.props.slides.map(function(slide, index) {
				return (
					<SwiperSlide key={`image`+index}>
						<div className="cell product-grid__product" >
							<a href={slide.path} className={slide.info.category + " product-grid__product--link"}>
								<div className="product-grid__product--image-wrapper">
									<span className="cat-badge">{slide.info.category}</span>
									<img className="lazyload" loading="lazy" data-src={slide.image} alt={slide.alt} />
									{/* <div className="product-grid__product--image" style={{background: `url(` + slide.image + `)`}}></div> */}
									<div className="product-grid__product--overlay"></div>
												<div className="product-grid__product--name">
										{this.renderInfo(slide.info)}
									</div>
								</div>
							</a>
						</div>
					</SwiperSlide>
				)
			}.bind(this))}

			</Swiper>


		)
	}
}

document.addEventListener('DOMContentLoaded', () => {

	var slider_list = document.querySelectorAll('.cpnt-slider'); // returns NodeList
	var sliders = [...slider_list]; // converts NodeList to Array

	sliders.forEach(slider => {
		const slides = JSON.parse( slider.getAttribute('slides') );
		const initialSlides = slider.getAttribute('initialSlides');
		ReactDOM.render(
			<ImageSlider slides={slides} initialSlides={initialSlides} />,
			slider,
		)
	});
})
